

.home-title {

  font-size: 4rem;
  font-family: Luckiest Guy, cursive;
  text-align: center;
  background: linear-gradient(to bottom, white, pink, red);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  pointer-events: none;
  opacity: 0;
  animation: fade .6s ease-in .15s 1 forwards ;
  margin-top: 15%;
  margin-bottom: 15%;
  -webkit-text-stroke: .1vw black;
  max-width: 100%
  
}
@keyframes fade{
0%{
opacity: 0;
}
100%{
opacity: 1;
}
}

@media (prefers-color-scheme: dark){
  /*blog style*/
  .blog-card {
    background: rgb(86, 88, 105) !important;
    color: white;
 
  }
  .title {
    text-align: center;
    font-size: 2em;
    color: white  !important;
    padding: .5em;
  }
  .image-caption {
    text-align: left;
    font-size: 0.8em;
    margin-bottom: 10px;
    font-style: italic;
    color: white;
  }
  .content {
    padding:.7em;
    color: white;
  }
  .my-navbar {
    background-color: rgb(33, 37, 41);
    color: white !important;
  }

  .my-navbar-offcanvas {
    background-color: rgb(33, 37, 41);
    color: white !important;
  }
}

@media (prefers-color-scheme: light){
  /*blog style*/
  .blog-card {
    background: white !important;
    color: black;
  }
  .title {
    text-align: center;
    font-size: 2em;
    padding: .5em;
    color: black;
  }
  .image-caption {
    text-align: left;
    font-size: 0.8em;
    margin-bottom: 10px;
    font-style: italic;
    color: black;
  }
  .content {
    padding:.7em;
    color: black;
  }
  .my-navbar {
    background-color: white !important;
    color: black;
  }

  .my-navbar-offcanvas {
    background-color: white;
    color: black !important;
  }
}




/* Image Gallery styles */
.image-gallery {
  display: flex;
  flex-wrap: wrap;
}

.image-thumbnail {
  flex: 0 0 33.333%;
  max-width: 33.333%;
  padding: 2px;
  cursor: pointer;
}

.image-thumbnail img {
  width: 100%;
  height: auto;
}

@media (max-width: 768px) {
  .image-thumbnail {
    flex: 0 0 100%;
    padding-top: 2px;
    padding-bottom: 2px;
    max-width: 100%;
  }

  
}
.expanded {
  flex: 0 0 100%;
  max-width: 100%;
  z-index: 1;
}

.expanded-image-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.9);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 1;
}

.expanded-image-overlay img {
  max-width: 100%;
  max-height: 100%;
  z-index: 1;
}

@media (max-width: 800px) {
  .image-description {
    /* apply styles for small screens here */
    color: white;
    background-color: black;
    position: fixed;
    margin-top: 70vh;
  }
}

@media (min-width: 801px) and (max-width: 1200px) {
  .image-description {
    color: white;
    background-color: black;
    position: fixed;
    margin-top: 70vh;

  }
  .blog-card .card-img {
    display: block;
    margin: 0 auto;
    width: 60%;
    padding: .5rem;
  }
}

@media (min-width: 801px) {
  .blog-card .card-img {
    display: block;
    margin: 0 auto;
    width: 60%;
    padding: .5rem;
  }

  .image-caption{
    display: block;
    text-align: center;
    padding : .5rem
  }
}
@media (min-width: 1201px) {
  .image-description {
    color: white;
    background-color: black;
    position: relative;
    margin-top: 50vh;
  }

}
.content-container {
  min-height: calc(34vh);
}
/* .footer--pin {
  position: relative;
  left: 0;
  bottom: 0;
} */
/* .App{
  background-color: black;
} */

/*Tic Tac Toe*/
.square {
  display: block;
  width: 100px;
  height: 100px;
  border: 1px solid #ccc;
  font-size: 48px;
  text-align: center;
  line-height: 100px;
}

.board {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(3, 1fr);
}

.social-media{
padding: 1%;
margin: 1%;

}

/*code block style*/
.line-number {
  display: inline-block;
  width: 3em;
  text-align: right;
  margin-right: 0.5em;
  color: #aaa;
}

pre {
  white-space: pre-wrap;
  background-color: #f6f8fa;
  color: black;
  border-radius: 3px;
  padding: 1em;
}

.code-block {
  position: relative;
}

.copy-button {
  position: absolute;
  top: 0;
  right: 0;
  border: none;
  background: transparent;
  color: #aaa;
  font-size: 0.8em;
  padding: 0.5em;
  cursor: pointer;
}


/* .const-darkStyle {
  background-color: '#333';
  color: '#fff'
};

.const-lightStyle  {
  background-color: '#fff';
  color: '#333'
}; */